<template>
    <div class="chargebacks-info">
        <b-button class="mb-3 col-auto" variant="light" @click="$router.go(-1)">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div v-if="chargeback" class="chargebacks-info__number">
            Operation {{ chargeback.id }}
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <ui-card title="Chargeback information">
                    <div v-if="loading" class="py-5 text-center">
                        <custom-spinner class="spinner-border-md" />
                    </div>
                    <template v-else>
                        <ui-list>
                            <ui-list-item v-if="chargeback.transaction" title="Transaction ID:">
                                <template #text>
                                    <router-link class="text-decoration-none" :to="{ name: 'transactions-info', query: { id: chargeback.transaction.id } }">
                                        {{ chargeback.transaction.transaction_id }}
                                    </router-link>
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Gateway:">
                                <template #text>
                                    <span class="text-uppercase">{{ chargeback.gateway }}</span>
                                </template>
                            </ui-list-item>
                            <ui-list-item v-if="chargeback.receiver" title="Receiver:">
                                <template #text>
                                    {{ chargeback.receiver.first_name }} {{ chargeback.receiver.last_name || '' }}
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Date:">
                                <template #text>
                                    {{ $dayjs(chargeback.created_at).format('LL, LTS') }}
                                </template>
                            </ui-list-item>
                            <ui-list-item title="Reason:" :text="chargeback.reason" />
                            <ui-list-item v-if="chargeback.expiration_at" title="Expiration date:">
                                <template #text>
                                    {{ $dayjs(chargeback.expiration_at).format('LL, LTS') }}
                                </template>
                            </ui-list-item>
                        </ui-list>
                    </template>
                </ui-card>
            </div>
            <div class="col-12 col-lg-6" v-if="CAN(['write_chargebacks'])">
                <ui-card>
                    <div v-if="loading" class="py-5 text-center">
                        <custom-spinner class="spinner-border-md" />
                    </div>
                    <template v-else>
                        <h5 class="text-center">Chargeback Status</h5>
                        <div class="text-center mb-2">
                            The chargeback is currently marked as <ui-status :status="chargeback.status"></ui-status>
                            <br />
                            you can change the status by clicking the buttons below:
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-xl-4">
                                <button
                                    :disabled="chargeback.status === 'lost' || changingStatus.lost || changingStatus.won"
                                    class="btn btn-sm btn-danger text-white w-100 mt-3"
                                    @click="changeStatus('lost')"
                                >
                                    <custom-spinner v-if="changingStatus.lost" class="spinner-border-sm me-1" />
                                    <span v-else class="far fa-times-circle"></span> Lost
                                </button>
                            </div>
                            <div class="col-lg-6 col-xl-4">
                                <button
                                    :disabled="chargeback.status === 'won' || changingStatus.lost || changingStatus.won"
                                    class="btn btn-sm btn-success text-white w-100 mt-3"
                                    @click="changeStatus('won')"
                                >
                                    <custom-spinner v-if="changingStatus.won" class="spinner-border-sm me-1" />
                                    <span v-else class="far fa-check-circle"></span> Won
                                </button>
                            </div>
                        </div>
                    </template>
                </ui-card>
                <disputes v-if="!loading && chargeback.files && chargeback.files.length" :chargeback="chargeback" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomSpinner from '@/components/ui/CustomSpinner.vue';
import Disputes from '@/components/chargebacks/Disputes.vue';
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: {
        CustomSpinner,
        Disputes,
        UiCard,
        UiList,
        UiListItem,
        UiStatus
    },
    data() {
        return {
            changingStatus: {
                lost: false,
                won: false,
            },
            chargeback: null,
            chargeback_id: this.$route.query.id,
            loading: true,
        };
    },
    mounted() {
        this.getChargeback();
    },
    methods: {
        async getChargeback() {
            try {
                this.loading = true;
                const { data } = await this.$axios.get(`/_/chargebacks/${this.chargeback_id}`);
                this.chargeback = data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async changeStatus(status) {
            try {
                this.changingStatus[status] = true;

                const { data } = await this.$axios.put(`/_/chargebacks/${this.chargeback_id}`, { status });
                
                this.chargeback.status = data.status;
                this.$toast.success('Chargeback updated successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.changingStatus[status] = false;
            }
        },
    },
};
</script>

<style lang="scss">
.chargebacks-info {
    &__number {
        font-size: 0.95rem;
        margin-bottom: 1rem;
    }
}
</style>
