<template>
    <ui-card
        class="disputes"
        title="Chargeback disputes"
        description="Below you can see the list of documents uploaded in the dispute:"
    >
        <ui-list v-if="chargeback.files">
            <ui-list-item v-for="file in chargeback.files" :key="file.id" :title="`${file.input}:`">
                <template #text>
                    <ui-link :link="file.path" class="disputes__file-name" target="_blank" rel="noopener noreferrer" weight="medium">
                        <span class="fas fa-file"></span> {{ file.name }}
                    </ui-link>
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiLink from '@/components/ui/Link.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';

export default {
    name: 'Disputes',
    components: {
        UiCard,
        UiLink,
        UiList,
        UiListItem,
    },
    props: {
        chargeback: {
            type: Object,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.disputes {
    &__file-name {
        word-break: break-all;
    }
}
</style>
